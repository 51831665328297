<template>
  <div class="tw-absolute tw-text-xl tw-text-yellow-300 tw-right-2 tw-bottom-2">
    {{ users.length }}
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  computed: {
    ...sync([
      'online/users', //
    ]),
  },

  watch: {
    users: {
      handler() {
        // console.log('UserCount.online.users', this.users.length);
      },
      deep: true,
    },
  },
};
</script>
